import React, { Component } from 'react'
import debugWrapper from 'debug'
import gt from 'lodash/get'
import $badger from '../../lib/badger'
import config from '../../config'
import { isNumeric, getDeviceDebug } from '../../lib/utils'
import EMEPlayer from './EMEPlayer'
import './Player.scss'
import { connect } from 'react-redux'
import { addError } from '../../store/modules/modal'
import ERRORS from '../../errors/messages'

const debug = debugWrapper('app:player')

class Player extends Component {
  constructor (props) {
    super(props)
    this.emit = 0
    this.player = React.createRef()
  }

  static defaultProps = {
    disableTimeUpdates: false
  }

  componentDidMount () {
    this._lastTimeUpdate
    = this._actualTime
    = this.props.currentTime
    this.seeked = false
    try {
      this.player.currentTime = this._lastTimeUpdate
    } catch(e) {
      console.warn('tried to set player current time when it likely was not ready: %s', e.message)
      console.trace && console.trace()
    }
  }
    

  componentWillUnmount () {
    this.stopMonitoringPlayback()
    clearTimeout(this.emit)
  }

  monitorPlayback () {
    // triggered by onPlay
    if (!isNaN(config.player.heartbeat_frequency)) {
      clearInterval(this._heartbeat)
      this._heartbeat = setInterval(() => {
        if (this.player) {
          let altDuration
          if (!this.player.paused) {
            $badger.userActionMetricsHandler('NormalPlaybackHeartbeat', { currentTime: this.player.currentTime })
            altDuration = this.props.duration - 0.1
          } else {
            $badger.userActionMetricsHandler('PausedPlaybackHeartbeat', { currentTime: this.player.currentTime })
            altDuration = this.props.duration - 0.5
          }
          if ((this.props.duration > 0 && (this.player.currentTime >= altDuration ||
            this.props.currentTime >= altDuration)) ||
            (this._tempTime > 0 && this._tempTime === this.player.currentTime && !this.player.paused)) this.onEnded()
          this._tempTime = this.player.currentTime
        } else {
          const players = document.getElementsByClassName('hls-player')
          const player = players.length ? players[0] : null
          if (player) {
            this.player = player
          } else {
            $badger.userActionMetricsHandler('AbNormalPlaybackHeartbeat', { message: 'No Player Object' })
          }
          debug(`No player to monitor playback with`)
        }
      }, config.player.heartbeat_frequency)
    } else {
      const { currentUrl } = this.props
      $badger.userActionMetricsHandler('HeartbeatsDisabled', {
        heartbeatFrequency: config.player.heartbeat_frequency,
        currentUrl
      })
    }
  }

  stopMonitoringPlayback () {
    this._tempTime = 0
    clearInterval(this._heartbeat)
  }

  onEnded () {
    const { sendPlaybackEvent, setPlaybackEvent, setPlayerState, onEnded } = this.props
    this.stopMonitoringPlayback()
    sendPlaybackEvent('stop', 'finish')
    setPlaybackEvent('start', 'autoAdvance')
    setPlayerState('paused')
    onEnded()
  }

  errorHandler (e, code) {
    const { currentUrl } = this.props
    debug('Playback Error on stream %s', currentUrl, e)
    $badger.errorMetricsHandler('PlaybackError', false, code, {
      message: e ? e.message : 'no message passed',
      url: currentUrl
    })
  }

  checkIfPlayed () {
    clearTimeout(this.__checktimeout)
    this.__checktimeout = setTimeout(() => {
      if (this.player) {
        if (this.player.currentTime < 0.001) {
          // this could also be triggered by autoplaying the song and immediately pausing it
          this.errorHandler(new Error('Stream has never seemed to play'), 302)
        }
      }
    }, config.player.timeout_check_frequency)
  }

  componentDidUpdate (prevProps) {
    if (!this.player) return

    const { currentTime, duration, progressBarTime, playerControlsState, playerState, currentUrl, setPlayerState, tracks } = this.props
    const oldcurrentUrl = prevProps.currentUrl
    if (currentUrl !== oldcurrentUrl) {
      const resumeAt = 0
      this._lastTimeUpdate = resumeAt
      this._actualTime = resumeAt
      this.updateTimes(resumeAt)

      this.emit = setTimeout(() => {
        if (this.player.paused && !this.props.showModal) setPlayerState('playing')
      }, 1000)
    }

    if (playerState === 'playing' && prevProps.playerState === 'paused') {
      this.checkIfPlayed()
    }

    if (tracks && tracks.event === 'start' && tracks.reason === 'userAdvanceBackward') {
      this.props.sendPlaybackEvent(tracks.event, tracks.reason)
      this.props.clearPlaybackEvent()
    }

    if (prevProps.currentTime !== currentTime && isNumeric(currentTime)) {
      if (currentTime === 0) {
        this._actualTime = 0
        this._lastTimeUpdate = 0
        try {
          this.player.currentTime = 0
        } catch (e) {
          console.info("can not set current time %s ", e.message)
          console.trace && console.trace()
        }
      }
    }

    if (!this.seeked && progressBarTime !== currentTime) this.seeked = true

    const pausedState = this.player.paused ? 'paused' : 'playing'
    if (pausedState !== playerState) {
      // will play or pause the song
      debug(`Player - playerState ${playerState} playerControlsState: ${playerControlsState} player is ${pausedState} currentUrl: ${currentUrl}`)
      if (playerState === 'playing' && this.player.paused && currentUrl === oldcurrentUrl &&
        !(duration > 0 && duration < progressBarTime + 1 && currentTime > 0)) { // dont call play when at end of current song
        try {
          debug('Calling play on player')
          this.player.play()
        } catch (e) {
          $badger.appActionMetricsHandler(
            'FAILED_TO_PLAY', 
            { message: e ? e.message : 'No message' }, 
            gt(this.props, 'trackInstance.trackDefinitionData.trackTag'), 
            gt(this.props, 'trackInstance.trackDefinitionData.title'), 
          )
        }
      } else if (playerState === 'paused' && !this.player.paused) {
        try {
          debug('Calling pause on player')
          this.player.pause()
        } catch (e) {
          $badger.appActionMetricsHandler(
            'FAILED_TO_PAUSE', 
            { message: e ? e.message : 'No message' }, 
            gt(this.props, 'trackInstance.trackDefinitionData.trackTag'), 
            gt(this.props, 'trackInstance.trackDefinitionData.title'), 
          )
        }
      }
    }
  }

  updateTimes (time) {
    this._lastTimeUpdate = time
    if (this.player) {
      this.props.setCurrentTime(this._actualTime)
    }
  }

  onProgress () {
    const { setBufferedTime } = this.props
    let duration = this.player.duration
    if (duration > 0) {
      for (let i = 0; i < this.player.buffered.length; i++) {
        if (this.player.buffered.start(this.player.buffered.length - 1 - i) < this.player.currentTime) {
          const buffered = this.player.buffered.end(this.player.buffered.length - 1 - i)
          setBufferedTime(buffered)
          break
        }
      }
    }
  }

  onTimeUpdate (time) {
    const { disableTimeUpdates, currentTime } = this.props
    if (!disableTimeUpdates && time > 0) {
      if (time - this._actualTime > 1) {
        this._actualTime = time
        this.updateTimes(time)
      } else if (time < this._actualTime) {
        // X1 receives time=0 onPlay
        if (currentTime !== this._actualTime) {
          // scrubbed left / right
          this._actualTime = currentTime
          this._lastTimeUpdate = currentTime
        }
        if (this._lastTimeUpdate > time) this._lastTimeUpdate = time
        if (time - this._lastTimeUpdate > 1) {
          // normal pause/play
          this._actualTime += time - this._lastTimeUpdate
          $badger.userActionMetricsHandler('onTimeUpdate', { adjustedTime: this._actualTime })
          this.updateTimes(time)
        }
      }
    }
  }
  soundbarError() {
    debug("Soundbar Stalled.")
    this._soundbar_stalled = false
    $badger.userActionMetricsHandler('PlayerOnError', {error: 'SoundbarStalled'})
    this.props.addError(ERRORS.soundbar, false)
  }
  clearSoundbar(arg = "unknown") {
    if( this._soundbar_stalled) {
      debug("Soundbar stall cleared, reason: %s", arg)
      clearTimeout(this._soundbar_timeout)
      this._soundbar_stalled = false
    }
  }
  soundbarStalled() {
    debug("Starting Soundbar stall timeout %s", config.soundbarStallTimeout)
    this._soundbar_timeout = setTimeout(this.soundbarError.bind(this), config.soundbarStallTimeout)
    this._soundbar_stalled = true
  }
  onStalled() {
    getDeviceDebug().then((r = {}) => {
      const { boardType = 'UNKNOWN' } = r
      if(boardType.indexOf("K6LP") === 0) {
        debug("Soundbar Stalled on bad LG Chipset")
        this.soundbarStalled()
      }
    })
  }
  render () {
    const {
      tracks,
      sendPlaybackEvent,
      clearPlaybackEvent,
      playerControlsState,
      disableInitOnUpdate,
      setPlayerControlsState,
      gotDuration,
      currentUrl,
      playerState,
      onReadyStateChange,
      onLoadStart,
      onLoadEnd,
      disableTimeUpdates,
    } = this.props

    return (
      <div ref={(div) => this._wrapperDiv = div} className={'playerWrapper'}>
        <EMEPlayer
          src={currentUrl}
          controls={true}
          disableInitOnUpdate={disableInitOnUpdate}
          autoPlay={playerState === 'playing'}
          preload="true"
          setPlayer={e => this.player = e}
          videoProps={{
            onError: event => {
              debug("On Error called.")
              if(this._soundbar_stalled) {
                this.soundbarError()
              } else {
                $badger.userActionMetricsHandler('PlayerOnError', { error: event.target.error })
                event.persist()
                const e = event.target.error
                this.errorHandler(e, 300)
              }
            },
            onProgress: event => {
              this.clearSoundbar("On Progress")
              this.onProgress()
            },
            onTimeUpdate: event => {
              this.clearSoundbar("On Time Update")
              event.persist() // Not sure what I was doing wrong but this was required on some events or React would get mad
              if (!disableTimeUpdates) this.onTimeUpdate(event.target.currentTime)
            },
            onLoadStart: (event) => {
              this.clearSoundbar("On Load Start")
              $badger.userActionMetricsHandler('PlayerOnLoadStart', {readyState: event.target.readyState})
              event.persist()
              onReadyStateChange(event.target.readyState)
              onLoadStart()
            },
            onLoadedData: (event) => {
              this.clearSoundbar("On Loaded Data")
              $badger.userActionMetricsHandler('PlayerOnLoadedData', {readyState: event.target.readyState})
              event.persist()
              onReadyStateChange(event.target.readyState)
              onLoadEnd()
            },
            onCanPlay: (event) => {
              this.clearSoundbar("On Can Play")
              $badger.userActionMetricsHandler('PlayerOnCanPlay', {readyState: event.target.readyState})
              event.persist()
              onReadyStateChange(event.target.readyState)
              // add this if needed: onCanPlay()
            },
            onLoadedMetadata: event => {
              this.clearSoundbar("On Loaded Metadata")
              if (tracks.event) {
                sendPlaybackEvent(tracks.event, tracks.reason)
                clearPlaybackEvent()
              }
              $badger.userActionMetricsHandler('PlayerOnLoadedMetadata', {readyState: event.target.readyState})
              event.persist()
              onReadyStateChange(event.target.readyState)
              gotDuration(event.target.duration)
            },
            onPause: (event) => {
              $badger.userActionMetricsHandler('PlayerOnPause', {currentTime: event.target.currentTime})
              event.persist()
              if (playerControlsState === 'playing') setPlayerControlsState('paused')
            },
            onStalled: this.onStalled.bind(this),
            onPlay: (event) => {
              this.monitorPlayback()
              $badger.userActionMetricsHandler('PlayerOnPlay', {currentTime: event.target.currentTime})
              event.persist()
              if (playerControlsState === 'paused') setPlayerControlsState('playing')
              if (this.seeked) {
                try {
                  this.player.currentTime = this.props.currentTime
                  this.seeked = false
                } catch (_) {}
              }
            }
          }}
        />
      </div>
    )
  }
}
export default connect(null, {addError})(Player)