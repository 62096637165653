import { call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import i18n from 'i18next'
import franc from 'franc-min'
import gt from 'lodash/get'
import API from '../../services/service'
import { SET_AUTH_DATA } from '../modules/auth'
import { SET_LNG } from '../modules/lng'
import { noha } from '../../lib/utils'
import config from '../../config'

function * setLanguage () {
  let code = config.default_language
  try {
    const QUERY = 'adelaidgoldeneggs'
    const { data } = yield call(API.loadNavigationNode, `/widescreen_search/?keywords=${QUERY}`, false)
    const node = gt(data, `navigationNodeDescriptions.${noha(data.result)}.items[0]`)
    const label = gt(data, `itemDescriptions.${noha(node)}.itemLabel`)
    const guess = franc(label.replace(QUERY, ''))

    switch (guess) {
      // -> unknown language -> eng
      case 'und':
        break
      default:
        code = guess
        break
    }

  } catch (_) { }

  yield put({ type: SET_LNG, code })
}

function * changeLanguage ({ code }) {
  yield i18n.changeLanguage(code)
}

function * lngSaga () {
  yield takeEvery(SET_LNG, changeLanguage)
  yield takeLatest(SET_AUTH_DATA, setLanguage)
}

export default lngSaga
