import React from 'react'
import gt from 'lodash/get'
import './PlaylistMenu.scss'
import ListMenu, { calculateOffsetHeight } from '../../lib/reactv-redux/SlotMenuRedux'
import PlaylistMenuItem from '../PlaylistMenuItem'
import PropTypes from 'prop-types'
import { isFunction } from '../../lib/utils'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { ScrollButton, ScrollButtonPlaceholder, ScrollButtons } from '../../components/MouseNavigation'
import createDebug from 'debug'

const debug = createDebug('app:PlaylistMenuHorizontalLoadingMenu')

class PlaylistMenu extends React.Component {
  constructor(p) {
    super(p)

    this.state = {
      index: 0,
      slotIndex: 0,
      max: gt(p, 'data.length', 1) - 1,
    }
  }

  componentDidUpdate () {
    const max = gt(this.props, 'data.length', 1) - 1
    if (this.state.max !== max) {
      debug('Updating Menu State.max')
      this.setState({ max })
    }
  }

  handleMenuState ({ index }) {
    if (this.state.index !== index) {
      debug('Updating Menu State.index')
      this.setState({ index })
    }
  }

  setModifiers ({ increment, decrement }) {
    this.__incrementer = increment
    this.__decrementer = decrement
  }

  increment () {
    if (isFunction(this.__incrementer)) this.__incrementer()
    else console.warn('Incrementer is not a funciton', this.__incrementer)
  }

  decrement () {
    if (isFunction(this.__decrementer)) this.__decrementer()
    else console.warn('Decrementer is not a funciton', this.__decrementer)
  }

  render () {
    const hasLeft = (this.state.index - this.state.slotIndex > 0)
    const hasRight = (this.state.index < this.state.max)
    debug(`${this.props.menuid} - hasLeft ${hasLeft} hasRight: ${hasRight}`, this.state)
    return (
      <React.Fragment>
        <ListMenu
          {...this.props}
          onChange={this.handleMenuState.bind(this)}
          calculateStyle={(currentState, newState, ref) => {
            if (newState.index === currentState.index) {
              return null
            }

            if (newState.index === 0) {
              return { transform: 'none' }
            }

            return { transform: `translateY(-${calculateOffsetHeight(ref, newState.index, newState.slotIndex)}px)` }
          }}
          modifiers={this.setModifiers.bind(this)}
          slots={this.props.slots ? this.props.slots : 8}
          renderItem={PlaylistMenuItem}
          className='PlaylistMenu'
        />
        <ScrollButtons
          className="scrolledButtons"
          style={{
            left: '3.125rem',
            transform: 'none'
          }}
        >
          {hasRight ? <ScrollButton onClick={this.increment.bind(this)}><FiChevronDown size={'2em'}/></ScrollButton> : <ScrollButtonPlaceholder />}
          {hasLeft ? <ScrollButton onClick={this.decrement.bind(this)}><FiChevronUp size={'2em'}/></ScrollButton> : <ScrollButtonPlaceholder />}
        </ScrollButtons>
      </React.Fragment>
    )
  }
}

PlaylistMenu.propTypes = {
  menuid: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired
}

export default PlaylistMenu
