import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import style from './PlayerControls.scss'
import Button from '../PlayerControlButton'
import ThumbDownIcon from '../../assets/images/icon/thumb-down.js'
import ThumbUpIcon from '../../assets/images/icon/thumb-up.js'
import PauseIcon from '../../assets/images/icon/pauseButton.js'
import ShuffleIcon from '../../assets/images/icon/shuffle.js'
import PreviousIcon from '../../assets/images/icon/previous-button.js'
import NextIcon from '../../assets/images/icon/next-button.js'
import PlayIcon from '../../assets/images/icon/play-icon.js'
import CloseIcon from '../../assets/images/icon/closeButton'
import { Space } from '../../lib/reactv-navigation'
import { getRewardForwardStatus } from '../../lib/utils'

class PlayerControls extends Component {
  constructor(props) {
    super(props)

    this.clickHandler = this.clickHandler.bind(this)
  }
  componentDidMount() {
    this.props.changeFocus(this.context.getMenuId('pause'))()
  }
  clickHandler(e, cb) {
    try {
      const card = e.target
      if (card && card.classList.contains('focused')) {

        const rect = card.getBoundingClientRect()
        const span = document.createElement('span')

        span.className = 'clicked-pulse'
        span.style.top = `${e.pageY - rect.top}px`
        span.style.left = `${e.pageX - rect.left}px`
        span.style.height = span.style.width = `${Math.max(
          rect.width,
          rect.height
        )}px`
        card.appendChild(span)
      }
    } catch (_) {}

    // fire event handler
    try {
      cb(e)
    } catch (_) {}
  }
  render () {
    const {
      shuffle,
      showShuffle,
      toggleShuffle,
      giveThumbs,
      showThumbs,
      thumbRating,
      isFocused,
      changeFocus,
      playerControlsState,
      togglePlayState,
      forwardSkip,
      backwardSkip,
      onUp,
      onDown,
      onLeft,
      onRight
    } = this.props
    const {getMenuId} = this.context

    const thumbedDown = (thumbRating === 'thumbs_down');
    const thumbedUp = (thumbRating === 'thumbs_up');

    const classForThumbsDown = cx('regularButton', 'thumbs', {
      'thumbs--disabled': !!thumbedUp,
      'thumbsDown-on': (isFocused(getMenuId('thumbsDown')) && !thumbedDown),
      'thumbsDown-off': (!isFocused(getMenuId('thumbsDown')) && !thumbedDown),
      'thumbsDown-set-on': (isFocused(getMenuId('thumbsDown')) && thumbedDown),
      'thumbsDown-set-off': (!isFocused(getMenuId('thumbsDown')) && thumbedDown)
    })

    const classForThumbsUp = cx('regularButton', 'thumbs', {
      'thumbs--disabled': !!thumbedDown,
      'thumbsUp-on': (isFocused(getMenuId('thumbsUp')) && !thumbedUp),
      'thumbsUp-off': (!isFocused(getMenuId('thumbsUp')) && !thumbedUp),
      'thumbsUp-set-on': (isFocused(getMenuId('thumbsUp')) && thumbedUp),
      'thumbsUp-set-off': (!isFocused(getMenuId('thumbsUp')) && thumbedUp)
    })

    const classForShuffle = cx('regularButton', 'shuffle', {
      'actived': !!shuffle
    })

    const paused = playerControlsState === 'paused'
    const playPauseIconClass = cx('pauseButton', {
      paused: paused,
      'pause-on': playerControlsState !== 'paused' && isFocused(getMenuId('pause')),
      'pause-off': playerControlsState !== 'paused' && !isFocused(getMenuId('pause')),
      'play-on': playerControlsState === 'paused' && isFocused(getMenuId('pause')),
      'play-off': playerControlsState === 'paused' && !isFocused(getMenuId('pause')),
      largeButton: true
    })

    let additionalControls = (showThumbs) ? 'thumbUp' : (showShuffle ? 'shuffle' : null)
    const focusOnAdditionalControls = additionalControls ? changeFocus(getMenuId(additionalControls)) : null;

    const [canReward, canForward] = getRewardForwardStatus()
    return (
      <div className={cx(style.PlayerControls, {showThumbs}, {showShuffle})}>
        <Button
          disabled={false}
          mid={getMenuId('closeButton')}
          onClick={this.props.back}
          onMouseOver={function nextMouseOver() {
            changeFocus(getMenuId('closeButton'))()
          }}
          onBlur={() => this.hideToolTip('closeButton')}
          className={cx('closeButton')}
          focused={isFocused(getMenuId('closeButton'))}
          onUp={onUp}
          onDown={changeFocus(getMenuId('pause'))}
        >
          <CloseIcon />
        </Button>
        <div
          className='controls'>
          <Button
            mid={getMenuId('previousTrack')}
            disabled={!canReward}
            onClick={e => {
              this.clickHandler(e, backwardSkip)
            }}
            onMouseOver={function nextMouseOver() {
              changeFocus(getMenuId('previousTrack'))()
            }}
            onBlur={() => this.hideToolTip('previous')}
            className={cx('previousTrack')}
            focused={isFocused(getMenuId('previousTrack'))}
            onDown={onDown}
            onLeft={onLeft}
            onUp={changeFocus(getMenuId('closeButton'))}
            onRight={changeFocus(getMenuId('pause'))}
          >
            <PreviousIcon />
          </Button>
          <Button
            mid={getMenuId('pause')}
            menuid={getMenuId('pause')}
            className={playPauseIconClass}
            focused={isFocused(getMenuId('pause'))}
            onLeft={changeFocus(getMenuId('previousTrack'))}
            onUp={changeFocus(getMenuId('closeButton'))}
            onRight={changeFocus(getMenuId('nextTrack'))}
            onClick={e => {
              this.clickHandler(e, togglePlayState)
            }}
            onMouseOver={changeFocus(getMenuId('pause'))}
            onDown={onDown}
          >
            {paused ? (
              <PlayIcon /> // todo change to play icon
            ) : (
              <PauseIcon />
            )}
          </Button>
          <Button
            mid={getMenuId('nextTrack')}
            disabled={!canForward}
            className={cx('previousTrack next')}
            focused={isFocused(getMenuId('nextTrack'))}
            onDown={onDown}
            onLeft={changeFocus(getMenuId('pause'))}
            onUp={changeFocus(getMenuId('closeButton'))}
            onRight={focusOnAdditionalControls}
            onClick={e => {
              this.clickHandler(e, forwardSkip)
            }}
            onMouseOver={function nextMouseOver() {
              changeFocus(getMenuId('nextTrack'))()
            }}
          >
            <NextIcon />
          </Button>
        </div>
        {showThumbs &&
          <div className='controls AdditionalControls'>
            <Button
              mid={getMenuId('thumbUp')}
              menuid={getMenuId('thumbUp')}
              className={classForThumbsUp}
              focused={isFocused(getMenuId('thumbUp'))}
              onRight={changeFocus(getMenuId('thumbDown'))}
              onClick={e => {
                this.clickHandler(e, () => giveThumbs('thumbs_up'))
              }}
              onMouseOver={changeFocus(getMenuId('thumbUp'))}
              onDown={onDown}
              onLeft={changeFocus(getMenuId('nextTrack'))}
              onUp={changeFocus(getMenuId('closeButton'))}
            >
              <ThumbUpIcon />
            </Button>
            <Button
              mid={getMenuId('thumbDown')}
              menuid={getMenuId('thumbDown')}
              className={cx(classForThumbsDown, 'thumbDown')}
              focused={isFocused(getMenuId('thumbDown'))}
              onLeft={changeFocus(getMenuId('thumbUp'))}
              onUp={changeFocus(getMenuId('closeButton'))}
              onClick={e => {
                this.clickHandler(e, () => giveThumbs('thumbs_down'))
              }}
              onMouseOver={changeFocus(getMenuId('thumbDown'))}
              onDown={onDown}
              onRight={onRight}
            >
              <ThumbDownIcon />
            </Button>
          </div>
        }
        {showShuffle &&
          <div className='controls AdditionalControls'>
            <Button
              mid={getMenuId('shuffle')}
              menuid={getMenuId('shuffle')}
              className={classForShuffle}
              focused={isFocused(getMenuId('shuffle'))}
              onLeft={changeFocus(getMenuId('nextTrack'))}
              onRight={onRight}
              onDown={onDown}
              onClick={e => {
                this.clickHandler(e, toggleShuffle)
              }}
              onMouseOver={changeFocus(getMenuId('shuffle'))}
            >
              <ShuffleIcon />
            </Button>
          </div>}
      </div>
    )
  }
}

PlayerControls.propTypes = {
  togglePlayState: PropTypes.func.isRequired,
  backwardSkip: PropTypes.func.isRequired,
  forwardSkip: PropTypes.func.isRequired
}
PlayerControls.contextTypes = {
  getMenuId: PropTypes.func
}
export default Space(PlayerControls)
