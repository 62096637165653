import 'react-app-polyfill/ie9'
import './lib/polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './pages/App'
import registerServiceWorker from './registerServiceWorker';
import { pollToken } from './lib/utils';
import ErrorReporter from './lib/ErrorReporter';
import './i18n'

const bugsnagClient = ErrorReporter.init();
const ErrorBoundary = bugsnagClient.getPlugin('react');

if (!String.prototype.includes) {
  String.prototype.includes = function (search, start) {
    if (typeof start !== 'number') {
      start = 0
    }

    if (start + search.length > this.length) {
      return false
    } else {
      return this.indexOf(search, start) !== -1
    }
  }
}
if (!String.prototype.startsWith) {
  Object.defineProperty(String.prototype, 'startsWith', {
    value: function(search, rawPos) {
      var pos = rawPos > 0 ? rawPos|0 : 0;
      return this.substring(pos, pos + search.length) === search;
    }
  });
}

try {
  const lt = (typeof localStorage !== 'undefined' && localStorage) ? localStorage : {}
  lt.debug = 'request:*, app:*'
} catch (e) {
  console.error('error setting debug', e.message)
}

// request new access token at intervals
const refreshPoll = 55 * 60 * 1000 // 55 minutes
setInterval(() => { pollToken() }, refreshPoll)

ReactDOM.render(
<ErrorBoundary>
  <App />
</ErrorBoundary>,
document.getElementById('root')
);
registerServiceWorker();

function dismissQuickLaunch() {
  try {
    if(window.$badger && window.$badger.launchCompletedMetricsHandler)  {
      window.$badger.launchCompletedMetricsHandler();
      console.info("Launch Completed Metrics Handler Called")
    }
    if( window.$badger && window.$badger.dismissLoadingScreen) {
      window.$badger.dismissLoadingScreen();
      console.info("Dismiss Loading Screen Called")
    }
  } catch(e) {
    console.error(e);
  }
}
if (window.$badger && window.$badger.active()) {
  console.info("onMoneyBadger Ready Calling Dismiss Quick Launch")
  dismissQuickLaunch()
} else {
  document.addEventListener('onMoneyBadgerReady', () => {
    console.info("onMoneyBadger Ready Calling Dismiss Quick Launch")
    dismissQuickLaunch()
  })
}

if(!window.$badger) {
  console.warn("No global $badger set, did script load get blocked?")
}


// import Map from 'es6-map/polyfill'
// import Assign from 'es6-object-assign'
// Assign.polyfill()
// window.Map = Map
// console.info('Object.assign', Object.assign)
