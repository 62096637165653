import React  from 'react'
import { connect } from 'react-redux'
import gt from 'lodash/get'
import up from 'url-parse'
import { loadChildNode } from '../../store/modules/music'
import { readyToDisplay, fetchDataFor } from '../../store/modules/pages'
import { noha, mergePath, makeNodeKey } from '../../lib/utils'
import { updateMenu } from '../../lib/reactv-redux/MenusReducer'
import { getMainCatalogData, getNavigationNodeSummariesSelector } from '../../lib/selectors/node_selectors'
import HomeComponent from './HomeNavigation'
import PageLoading from '../../components/PageLoading'

const mapStateToProps = state => ({
  showSettings: state.nav.showSettings,
  showModal: state.modal.showModal,
  catalog: getMainCatalogData(state),
  pathname: state.router.location.pathname,
  pageStatus: state.pages[state.router.location.pathname],
  navigationNodeSummaries: getNavigationNodeSummariesSelector(state),
  nodes: state.music.nodes,
})

const mapDispatchToProps = { loadChildNode, updateMenu, readyToDisplay, fetchDataFor }

class HomeContainer extends React.Component {
  componentDidMount () {
    const isRecent = this.props.pathname.includes('widescreen_recents')
    const isMyMusic = this.props.pathname.includes('widescreen_library')
    this.loadIfNeeded(!!isRecent || !!isMyMusic)
  }
  loadCheck() {
    const { catalog, nodes, pathname, pageStatus, navigationNodeSummaries } = this.props;
    if (!(catalog && pageStatus && pageStatus.render) && !this._loadChecked) {
      this._loadChecked = 1
      this._startLoad = new Date()
      console.info("Home not loaded, loading content %s", this._loadChecked)
    } else if (catalog && pageStatus && pageStatus.render && this._loadChecked === 1) {
      this._loadChecked = 2
      console.info(`Home Content Loaded in ${new Date() - this._startLoad}ms  we should be rendered`)
    }
  }
  componentWillUnmount () {
    clearInterval(this.emit)
  }

  componentDidUpdate () {
    this.loadIfNeeded()
    const { nodes, catalog, pathname, pageStatus, navigationNodeSummaries } = this.props
    if (catalog && (!pageStatus || !pageStatus.render)) {
      const lists = Object.keys(navigationNodeSummaries)
      let count = 0;
      for (let i=0; i < lists.length; i++) {
        const item = lists[i];
        const string = this.getNode(item)
        const node = nodes[string];
        if (node && node.result) count++
        else break
        if (count === lists.length) this.props.readyToDisplay(pathname)
      }
    }
  }

  getNode (item) {
    let summary = this.props.navigationNodeSummaries[item];
    if (summary.description.indexOf('#') === 0) {
      const description = noha(summary.description).replace(/_desc$/,'');
      return `/${description}/`
    } else {
      const path = mergePath('/widescreen_catalog/', summary.description)
      return up(path).pathname
    }
  }

  loadIfNeeded (force) {
    this.loadCheck()
    const { catalog, nodes, pathname, pageStatus, navigationNodeSummaries } = this.props;

    if (force) {
      this.emit = setTimeout(() => {
        this.loadIfNeeded(true)
      }, 30 * 1000) // load data again in next ~30 sec
    }

    if (!navigationNodeSummaries) return
    if (force || (catalog && (!pageStatus || !pageStatus.fetched))) {
      const lists = Object.keys(navigationNodeSummaries)
      lists.map(item => {
        const string = this.getNode(item)
        const node = nodes[string];
        if (force || !node) this.props.loadChildNode(string)
        return null
      })
      this.props.fetchDataFor(pathname)
    }
  }

  render () {
    const { catalog, navigationNodeSummaries, pathname, nodes, showModal, updateMenu, showSettings, pageStatus } = this.props;
    if (!(catalog && pageStatus && pageStatus.render)) {
      return <PageLoading />
    }
    const itemsData = gt(catalog, 'itemsData', [])
    const data = itemsData.filter(item => {
      const _summary = noha(item.navigationNodeSummary)
      const navNodeSum = navigationNodeSummaries[_summary]
      if (!navNodeSum
        || !navNodeSum.description
        || navNodeSum.numItemsOfInterest === 0) {
        return false
      }

      const node_key = makeNodeKey(navNodeSum.description).replace(/\/#.*/,'');
      /**
       * We hard-code upsell card, @see src/components/HomeMenuHorizontalLoadingMenu/HomeMenuHorizontalLoadingMenuContainer.js~#L87
       */
      if (node_key.includes('upsell-banner') === true) {
        return true
      }

      const node = nodes[node_key];
      if (!node
        || !node.navigationNodeDescriptions
        || !node.itemDescriptions) {
        return false
      }

      return true
    })
    const entryFocus = data.length > 0 ? `home:${pathname}` : null;
    return <HomeComponent
      slots={2}
      data={data}
      focused={!(showModal || showSettings)}
      pathname={pathname}
      updateMenu={updateMenu}
      menuid={'homespace'}
      onFocusItem='topnav'
      gridMenuId={entryFocus}
      entryFocus={entryFocus}
    />
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer)
