import React from 'react'
import i18next from 'i18next'
import ListMenu, { calculateOffset, SlotLinker } from '../../lib/reactv-redux/SlotMenuRedux'
import HomeMenuCard from '../HomeMenuCard'
import { HomeMenuContext } from '../HomeMenu/HomeMenu'
import store from '../../store'
import { isFunction, resize } from '../../lib/utils'
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi'
// import createDebug from 'debug'
// const debug = createDebug('app:HomeMenuHorizontalLoadingMenu')
const linker = new SlotLinker(store)

const calculateStyle = (currentState, newState, ref) => {
  if (newState.index > currentState.index && newState.slotIndex === currentState.slotIndex) {
    const transform = `translateX(-${calculateOffset(ref, newState.index, newState.slotIndex)}px)`
    return { transform }
  } else if (newState.index < currentState.index && currentState.slotIndex === 0) {
    const transform = `translateX(-${calculateOffset(ref, newState.index, 0)}px)`
    return { transform }
  } else {
    return null
  }
}

class HomeMenuHorizontalLoadingMenu extends React.Component {
  constructor (p) {
    super(p)
    this.__incrementer = null
    this.__decrementer = null

    this.container = React.createRef()
    this.arrowRight = React.createRef()
    this.arrowLeft = React.createRef()
    this.checkMouseCoordinates = this.checkMouseCoordinates.bind(this)
  }

  componentDidMount() {
    this.container.current.addEventListener('mousemove', this.checkMouseCoordinates)
  }

  componentWillUnmount() {
    this.container.current.removeEventListener('mousemove', this.checkMouseCoordinates)
  }

  checkMouseCoordinates({ clientX: x }) {
    const isActive = (nav, dir) => {
      if (!nav) {
        return
      }

      const coords = nav.getBoundingClientRect()
      const display = dir === 'left' ? coords.left + coords.width + resize(150) > x : coords.left - resize(150) < x

      if (display) {
        nav.classList.add('display')
      } else {
        nav.classList.remove('display')
      }
    }

    isActive(this.arrowRight.current, 'right')
    isActive(this.arrowLeft.current, 'left')
  }

  setModifiers ({ increment, decrement }) {
    this.__incrementer = increment
    this.__decrementer = decrement
  }

  increment () {
    if (isFunction(this.__incrementer)) this.__incrementer()
    else console.warn('Incrementer is not a function', this.__incrementer)
  }

  decrement () {
    if (isFunction(this.__decrementer)) this.__decrementer()
    else console.warn('Decrementer is not a function', this.__decrementer)
  }

  getScrollButtons (refs) {
    const hasLeft = (this.props.menuState && this.props.menuState.index - this.props.menuState.slotIndex > 0)
    const hasRight = (this.props.menuState && this.props.menuState.index < this.props.menuState.max)
   //  debug(`${this.props.menuid} - hasLeft ${hasLeft} hasRight: ${hasRight}`, this.props.menuState)
    if (this.props.focused) {
      return (
        <HomeMenuContext.Consumer>
          {({ onFocus }) => (
            <React.Fragment>
              {hasLeft && (
                <div
                  ref={refs.arrowLeft}
                  className={'HomeMenuHorizontalLoadingMenuDecrement IncArrow'}
                  >
                  <div
                    className='incrementArrow left'
                    onMouseOver={onFocus}
                    onClick={this.decrement.bind(this)}
                  >
                    <FiChevronLeft size={'2em'} />
                  </div>
                </div>
              )}
              {hasRight && (
                <div
                  ref={refs.arrowRight}
                  className={'HomeMenuHorizontalLoadingMenuIncrement IncArrow'}
                  >
                  <div
                    className='incrementArrow right'
                    onMouseOver={onFocus}
                    onClick={this.increment.bind(this)}
                  >
                    <FiChevronRight size={'2em'} />
                  </div>
                </div>
              )}
            </React.Fragment>
          )}
        </HomeMenuContext.Consumer>
      )
    }
  }

  render () {
    const { itemsData, name, menuid, focused, onClick, allMenuIDs, summary, slots, onFarLeft, allowExplicit, menuState } = this.props
    let data = (itemsData.length > 8) ? itemsData.slice(0, 5) : itemsData
    let isUpsell = summary.includes('upsell-banner')
    if (isUpsell === false && itemsData.length > 8) {
      data.push({ itemLabel: i18next.t('See more'), navigationNodeSummary: name, type: 'SEE_MORE' })
    } else if (isUpsell === true) {
      data = [Object.assign({}, data[0], {
        // itemLabel: i18next.t('Get access to\n tens of millions\n more songs.'),
        image: { uri: 'amazon_unlimited_logo.jpg' }
      })]
    }
    const usableSlots = !isNaN(slots) ? slots : 2;
    const mindex = menuState ? menuState.index : 0;
    const sindex = menuState ? menuState.slotIndex: 0;
    const startFocusable = mindex - sindex
    const endFocusable = startFocusable + usableSlots
    data.forEach((item, index) => item.focusable = index >= startFocusable && index < endFocusable)
    return (
      <div ref={this.container} className='HomeMenuHorizontalLoadingMenuWrapper'>
        <ListMenu
          data={data}
          menuid={menuid}
          renderItem={HomeMenuCard}
          className='HomeMenuHorizontalLoadingMenu'
          horizontal
          modifiers={this.setModifiers.bind(this)}
          focused={focused}
          onClick={onClick}
          slots={usableSlots}
          calculateStyle={calculateStyle}
          onChange={linker.link(menuid, allMenuIDs)}
          onFarLeft={onFarLeft}
          allowExplicit={allowExplicit}
        />
        {this.getScrollButtons(this)}
      </div>
    )
  }
}

export default HomeMenuHorizontalLoadingMenu
